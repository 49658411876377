import React from 'react'

import { Helmet } from 'react-helmet'

import Navbar from '../components/navbar'
import Footer from '../components/footer'
import './about.css'

const About = (props) => {
  return (
    <div className="about-container">
      <Helmet>
        <title>About - Raya Stone</title>
        <meta
          name="description"
          content="Discover RAYA Stone for top Egyptian marble and granite. Since 2004, blending traditional craftsmanship with modern tech for unparalleled luxury and quality."
        />
        <meta property="og:title" content="About - Raya Stone" />
        <meta
          property="og:description"
          content="Explore RAYA Stone's exquisite Egyptian marble and granite. Since 2004, crafting luxury with a blend of tradition and modern technology."
        />
        <meta
          property="og:image"
          content="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/53129d38-2401-4974-9d97-694189846ec3/f51369b8-80c0-46ef-912c-abce5eb3ce38?org_if_sml=1&amp;force_format=original"
        />
      </Helmet>
      <Navbar rootClassName="navbar-root-class-name"></Navbar>
      <div className="about-main">
        <div className="about-categories section-container column">
          <div className="about-container1 max-width-container">
            <div className="about-container2">
              <span className="about-text">
                <span>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <br></br>
                <br></br>
                <span>
                  Welcome to RAYA Stone, the epitome of excellence in Egyptian
                  marble and granite craftsmanship. Since our establishment in
                  2004, we have been dedicated to showcasing the timeless beauty
                  of Egyptian stone, renowned worldwide for its exceptional
                  quality and unique character.
                </span>
                <br className="about-text05"></br>
                <br></br>
                <br className="about-text07"></br>
                <br></br>
                <span>
                  At RAYA Stone, we understand the allure of Egyptian marble and
                  granite. With their exquisite colors, rich veins, and
                  unrivaled durability, these natural stones have adorned
                  historical landmarks and luxury spaces for centuries. We take
                  great pride in being a premier provider of Egyptian stone,
                  offering a wide range of selections that celebrate the legacy
                  and elegance of this ancient art form.
                </span>
                <br className="about-text10"></br>
                <br></br>
                <br className="about-text12"></br>
                <br></br>
                <span>
                  Our collection showcases the finest Egyptian marble and
                  granite, carefully sourced from quarries renowned for their
                  exceptional quality. Whether you seek the classic elegance of
                  Egyptian Beige Marble or the captivating allure of Halayeb
                  Granite, our team of skilled craftsmen and artisans are
                  dedicated to transforming your space into a masterpiece of
                  unparalleled beauty.
                </span>
                <br className="about-text15"></br>
                <br></br>
                <br className="about-text17"></br>
                <br></br>
                <span>
                  From elegant countertops and opulent flooring to intricate
                  sculptures, RAYA Stone specializes in crafting bespoke
                  solutions that seamlessly integrate Egyptian marble and
                  granite into your residential or commercial projects. We
                  combine traditional craftsmanship with state-of-the-art
                  technologies to ensure precision, attention to detail, and
                  superior quality in every endeavor.
                </span>
                <br className="about-text20"></br>
                <br></br>
                <br className="about-text22"></br>
                <br></br>
                <span>
                  At RAYA Stone, we believe that the client experience is just
                  as important as the final product. Our team of experts works
                  closely with you, architects, and designers to understand your
                  vision and provide personalized guidance throughout the entire
                  process. We prioritize open communication, transparency, and
                  timely delivery, ensuring your complete satisfaction every
                  step of the way.
                </span>
                <br className="about-text25"></br>
                <br></br>
                <br className="about-text27"></br>
                <br></br>
                <span>
                  As a socially responsible company, we are committed to
                  sustainability and environmental stewardship. We embrace
                  eco-friendly practices in our manufacturing processes,
                  striving to minimize our carbon footprint and promote
                  responsible sourcing of materials. By choosing RAYA Stone, you
                  not only embrace the elegance of Egyptian marble and granite
                  but also contribute to a greener future.
                </span>
                <br className="about-text30"></br>
                <br></br>
                <br className="about-text32"></br>
                <br></br>
                <span>
                  Explore our website and be inspired by the exquisite beauty
                  and craftsmanship that RAYA Stone offers. Whether you are
                  looking to create a luxurious residential oasis or elevate a
                  commercial space to new heights, our team is ready to bring
                  your vision to life. Contact us today and embark on a journey
                  that celebrates the timeless allure of Egyptian marble and
                  granite. Together, we will create spaces that will be admired
                  for generations to come.
                </span>
                <br></br>
                <span>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
              </span>
            </div>
          </div>
        </div>
      </div>
      <Footer rootClassName="footer-root-class-name"></Footer>
    </div>
  )
}

export default About
