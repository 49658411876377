import React from 'react'

import { Helmet } from 'react-helmet'

import Navbar from '../components/navbar'
import Footer from '../components/footer'
import './contact.css'

const Contact = (props) => {
  return (
    <div className="contact-container">
      <Helmet>
        <title>Contact - Raya Stone</title>
        <meta
          name="description"
          content="Discover RAYA Stone for top Egyptian marble and granite. Since 2004, blending traditional craftsmanship with modern tech for unparalleled luxury and quality."
        />
        <meta property="og:title" content="Contact - Raya Stone" />
        <meta
          property="og:description"
          content="Explore RAYA Stone's exquisite Egyptian marble and granite. Since 2004, crafting luxury with a blend of tradition and modern technology."
        />
        <meta
          property="og:image"
          content="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/53129d38-2401-4974-9d97-694189846ec3/f51369b8-80c0-46ef-912c-abce5eb3ce38?org_if_sml=1&amp;force_format=original"
        />
      </Helmet>
      <Navbar rootClassName="navbar-root-class-name1"></Navbar>
      <div className="contact-main">
        <div className="section-container contact-trending-items">
          <div className="max-width-container">
            <div className="contact-gallery">
              <div className="contact-left">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3457.222634238445!2d31.329152475793734!3d29.944274174974183!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x145839ea98c25b2b%3A0x9c65e14b20efba86!2zUmF5YSBTdG9uZSBGb3IgTWFyYmxlICYgR3Jhbml0ZSAtINix2KfZitipINiz2KrZiNmGINmE2YTYsdiu2KfZhSDZiCDYp9mE2KzYsdin2YbZitiq!5e0!3m2!1sen!2sde!4v1685474094781!5m2!1sen!2sde"
                  className="contact-iframe"
                ></iframe>
              </div>
              <div className="contact-right">
                <span className="contact-text">
                  <br></br>
                  <span>Contact Us at: </span>
                  <br></br>
                  <br></br>
                  <span>+49 (0) 176 66 85 7661</span>
                  <br></br>
                  <span>info@rayastone.com</span>
                  <br></br>
                  <span>
                    45A Shak El Thoaaban, 11729 Industrial Zone, Cairo, Egypt
                  </span>
                  <br></br>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer rootClassName="footer-root-class-name1"></Footer>
    </div>
  )
}

export default Contact
