import React from 'react'
import { Link } from 'react-router-dom'

import PropTypes from 'prop-types'

import './navbar.css'

const Navbar = (props) => {
  return (
    <div className={`navbar-navbar ${props.rootClassName} `}>
      <img alt={props.imageAlt} src={props.imageSrc} className="navbar-image" />
      <h3 className="navbar-text">
        <span className="">
          RAYA
          <span
            dangerouslySetInnerHTML={{
              __html: ' ',
            }}
          />
        </span>
        <span className="navbar-text2">
          STONE
          <span
            dangerouslySetInnerHTML={{
              __html: ' ',
            }}
          />
        </span>
        <span className="navbar-text3">FOR MARBLE &amp; GRANITE</span>
      </h3>
      <header data-role="Header" className="navbar-header max-width-container">
        <div className="navbar-middle">
          <div className="navbar-left"></div>
        </div>
        <div className="navbar-container">
          <div className="navbar-right">
            <Link to="/" className="navbar-link">
              {props.home}
            </Link>
            <Link to="/about" className="navbar-about navbar-link">
              {props.about}
            </Link>
            <Link to="/products" className="navbar-products navbar-link">
              {props.products}
            </Link>
            <Link to="/contact" className="navbar-contact navbar-link">
              {props.contact}
            </Link>
          </div>
          <div className="navbar-container1">
            <div className="navbar-navbar1">
              <div className="navbar-container2"></div>
              <div className="navbar-icons"></div>
            </div>
          </div>
        </div>
      </header>
   </div>
  )
}

Navbar.defaultProps = {
  contact: 'CONTACT',
  text1: 'PRODUCTS',
  about: 'ABOUT',
  text: 'ABOUT',
  imageSrc: '/logo3-200h.png',
  imageAlt: 'image',
  products: 'PRODUCTS',
  home1: 'HOME',
  text2: 'CONTACT',
  home: 'HOME',
  rootClassName: '',
}

Navbar.propTypes = {
  contact: PropTypes.string,
  text1: PropTypes.string,
  about: PropTypes.string,
  text: PropTypes.string,
  imageSrc: PropTypes.string,
  imageAlt: PropTypes.string,
  products: PropTypes.string,
  home1: PropTypes.string,
  text2: PropTypes.string,
  home: PropTypes.string,
  rootClassName: PropTypes.string,
}

export default Navbar
