import React from 'react'

import PropTypes from 'prop-types'

import './footer.css'

const Footer = (props) => {
  return (
    <div className={`footer-footer ${props.rootClassName} `}>
      <div className="max-width-container">
        <footer className="footer-footer1">
          <div className="footer-container">
            <h3 className="footer-text">
              <span className="">
                RAYA
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <span className="footer-text2">
                STONE
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <span className="footer-text3">FOR MARBLE &amp; GRANITE</span>
            </h3>
            <div className="footer-container1">
              <span className="footer-text4">{props.text}</span>
              <span className="footer-text5">{props.text1}</span>
              <span className="footer-text6">{props.text2}</span>
            </div>
          </div>
          <div className="footer-links-container"></div>
        </footer>
      </div>
    </div>
  )
}

Footer.defaultProps = {
  rootClassName: '',
  text1: '+49 (0) 176 66 85 7661',
  text2: 'info@rayastone.com',
  text: '45A Shak El Thoaaban, 11729 Industrial Zone, Cairo, Egypt',
}

Footer.propTypes = {
  rootClassName: PropTypes.string,
  text1: PropTypes.string,
  text2: PropTypes.string,
  text: PropTypes.string,
}

export default Footer
