import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import Navbar from '../components/navbar'
import ItemCard from '../components/item-card'
import Footer from '../components/footer'
import './home.css'

const Home = (props) => {
  return (
    <div className="home-container">
      <Helmet>
        <title>Raya Stone</title>
        <meta
          name="description"
          content="Discover RAYA Stone for top Egyptian marble and granite. Since 2004, blending traditional craftsmanship with modern tech for unparalleled luxury and quality."
        />
        <meta property="og:title" content="Raya Stone" />
        <meta
          property="og:description"
          content="Explore RAYA Stone's exquisite Egyptian marble and granite. Since 2004, crafting luxury with a blend of tradition and modern technology."
        />
        <meta
          property="og:image"
          content="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/53129d38-2401-4974-9d97-694189846ec3/f51369b8-80c0-46ef-912c-abce5eb3ce38?org_if_sml=1&amp;force_format=original"
        />
      </Helmet>
      <Navbar rootClassName="navbar-root-class-name2"></Navbar>
      <div className="home-main">
        <div className="home-categories section-container column">
          <div className="home-container1 max-width-container">
            <div className="home-container2">
              <span className="home-text">
                <span>
                  {' '}
                  Welcome to RAYA Stone, where we showcase the timeless beauty
                  of Egyptian marble
                </span>
                <br></br>
                <span>
                  {' '}
                  and granite Since 2004, we have been dedicated to the
                  exceptional quality and unique character of Egyptian stone.
                </span>
                <br></br>
                <br></br>
                <span>
                  {' '}
                  With exquisite colors, rich veins, and unmatched durability,
                  these natural stones have adorned historical landmarks and
                  luxury spaces for centuries. As a premier provider of Egyptian
                  stone, we take great pride in offering a wide range of
                  selections that celebrate the legacy and elegance of this
                  ancient art form.
                </span>
                <br></br>
                <span>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
              </span>
              <Link to="/about" className="home-navlink button">
                Read more
              </Link>
            </div>
          </div>
        </div>
        <div className="section-container">
          <div className="max-width-container">
            <div className="home-gallery">
              <div className="home-left">
                <ItemCard
                  name="Split-Face-Minya-B1"
                  value="5"
                  imageSrc="/mamor-antik%20(1)-photoroom-photoroom1-1500w.png"
                  rootClassName="rootClassName4"
                ></ItemCard>
              </div>
              <div className="home-right">
                <div className="home-top">
                  <div className="home-right1">
                    <ItemCard
                      name="Water-Fall-Minya"
                      value="299"
                      imageSrc="/2023-05-30at18.55-photoroom-photoroom-1500w.png"
                      rootClassName="rootClassName3"
                    ></ItemCard>
                  </div>
                </div>
                <div className="home-bottom">
                  <div className="home-right2">
                    <ItemCard
                      name="Cubes-Black"
                      value="49"
                      imageSrc="/4-1500w.png"
                      rootClassName="item-card-root-class-name6"
                    ></ItemCard>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Link to="/products" className="home-navlink1 button">
          See more
        </Link>
      </div>
      <Footer rootClassName="footer-root-class-name3"></Footer>
    </div>
  )
}

export default Home
