import React from 'react'

import { Helmet } from 'react-helmet'

import Navbar from '../components/navbar'
import ItemCard from '../components/item-card'
import Footer from '../components/footer'
import './products.css'

const Products = (props) => {
  return (
    <div className="products-container">
      <Helmet>
        <title>Products - Raya Stone</title>
        <meta
          name="description"
          content="Discover RAYA Stone for top Egyptian marble and granite. Since 2004, blending traditional craftsmanship with modern tech for unparalleled luxury and quality."
        />
        <meta property="og:title" content="Products - Raya Stone" />
        <meta
          property="og:description"
          content="Explore RAYA Stone's exquisite Egyptian marble and granite. Since 2004, crafting luxury with a blend of tradition and modern technology."
        />
        <meta
          property="og:image"
          content="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/53129d38-2401-4974-9d97-694189846ec3/f51369b8-80c0-46ef-912c-abce5eb3ce38?org_if_sml=1&amp;force_format=original"
        />
      </Helmet>
      <Navbar rootClassName="navbar-root-class-name3"></Navbar>
      <div className="products-main">
        <div className="section-container">
          <div className="max-width-container">
            <div className="products-gallery">
              <div className="products-left">
                <ItemCard
                  name="Split-Face-Minya-B1"
                  value="5"
                  imageSrc="/mamor-antik%20(1)-photoroom-photoroom1-1500w.png"
                  rootClassName="rootClassName8"
                ></ItemCard>
                <ItemCard
                  name="Sunny Minya"
                  value="299"
                  imageSrc="/20160723_134102-1500w.jpg"
                  rootClassName="rootClassName11"
                ></ItemCard>
                <ItemCard
                  name="Halayeb"
                  value="299"
                  imageSrc="/img_20180201_141539-1500w.jpg"
                  rootClassName="rootClassName14"
                ></ItemCard>
                <ItemCard
                  name="Rosa Nasser"
                  value="299"
                  imageSrc="/img_20180201_141704-1500w.jpg"
                  rootClassName="rootClassName15"
                ></ItemCard>
              </div>
              <div className="products-right">
                <div className="products-top">
                  <div className="products-right1">
                    <ItemCard
                      name="Water-Fall-Minya"
                      value="299"
                      imageSrc="/2023-05-30at18.55-photoroom-photoroom-1500w.png"
                      rootClassName="rootClassName9"
                    ></ItemCard>
                  </div>
                </div>
                <div className="products-bottom">
                  <div className="products-right2">
                    <ItemCard
                      name="Cubes-Black"
                      value="49"
                      imageSrc="/4-1500w.png"
                      rootClassName="item-card-root-class-name10"
                    ></ItemCard>
                    <ItemCard
                      name="Split-Face-Minya-B2"
                      value="299"
                      imageSrc="/109819539_209120310374132_6477597427341403238_n-photoroom-photoroom%20(2)-1500w.png"
                      rootClassName="rootClassName16"
                    ></ItemCard>
                  </div>
                </div>
                <ItemCard
                  name="Green Silvia Minya"
                  value="299"
                  imageSrc="/20160731_1256121-1500w.jpg"
                  rootClassName="rootClassName12"
                ></ItemCard>
                <ItemCard
                  name="White Silvia Minya"
                  value="299"
                  imageSrc="/whitesilvia-1500w.jpg"
                  rootClassName="rootClassName13"
                ></ItemCard>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer rootClassName="footer-root-class-name2"></Footer>
    </div>
  )
}

export default Products
